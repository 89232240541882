import * as THREE from 'three'
import { map, lerp } from './util.js'
// based on https://github.com/simondevyoutube/ThreeJS_Tutorial_ParticleSystems/blob/master/main.js

const vertShader = /* glsl */`
    uniform float pointMultiplier;

    attribute float size;
    attribute vec4 colour;
    attribute float angle;

    varying vec4 vColour;
    varying vec2 vAngle;

    void main() {
        
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);

        gl_Position = projectionMatrix * mvPosition;
        gl_PointSize = size * pointMultiplier / gl_Position.w;

        vColour = colour;
        vAngle = vec2(cos(angle), sin(angle));
    }
`
const fragShader = /* glsl */`
    uniform sampler2D diffuseTexture;
    // uniform float aspectMult;

    varying vec4 vColour;
    varying vec2 vAngle;

    void main() {
        vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;
        // coords.x /= aspectMult;
        gl_FragColor = texture2D(diffuseTexture, coords) * vColour;
    }
`



export class particle {
    constructor(texture, parent, scene, camera, options) {

        this.camera = camera
        this.initAspect = camera.aspect
        this.parent = parent
        this.options = {
            sRate: options.sRate !== undefined ? options.sRate : 1, //number of spawns per frame
            sRange: options.sRange !== undefined ? options.sRange : new THREE.Vector3(0,0,0), //spawn radius
            sSize: options.sSize !== undefined ? options.sSize : 0,
            eSize: options.eSize !== undefined ? options.eSize : 0.5,
            vSize: options.vSize !== undefined ? options.vSize : 1, //variation in size 
            modSize: options.modSize !== undefined ? options.modSize : true,
            sColour: options.sColour !== undefined ? options.sColour : new THREE.Vector3(1, 0, 0), //spawn colour
            eColour: options.eColour !== undefined ? options.eColour : new THREE.Vector3(0, 0, 1),
            sAlpha: options.sAlpha !== undefined ? options.sAlpha : 1,
            fadeIn: options.fadeIn !== undefined ? options.fadeIn : true,
            fadeOut: options.fadeOut !== undefined ? options.fadeOut : true,
            sVelocity: options.sVelocity !== undefined ? options.sVelocity : new THREE.Vector3(0, 0.02, 0), //spawn velocity
            eVelocity: options.eVelocity !== undefined ? options.eVelocity : new THREE.Vector3(0, 0, 0),
            vVelocity: options.vVelocity ? options.vVelocity : 0,
            vAngle: options.vAngle !== undefined ? options.vAngle : (2 * Math.PI),
            lifeTime: options.lifeTime !== undefined ? options.lifeTime : 100, //life in frames
            fadeInTime: options.fadeInTime !== undefined ? options.fadeInTime : 10, //time to fade before life in frames
            fadeOutTime: options.fadeOutTime !== undefined ? options.fadeOutTime : 10, //time to fade after life in frames
            blendMode: options.blendMode ? options.blendMode : THREE.NormalBlending,
            //anim
            motionScale: options.motionScale ? options.motionScale : 1,
            alphaScale: options.alphaScale ? options.alphaScale : 1,
            colorScale: options.colorScale ? options.colorScale : 1,
        }

        //round robin attribute texture would be nice, might be hefty performance though since thatd be 50+ times more texture references
        this.uniforms = {
            diffuseTexture: {
                value: texture
            },
            pointMultiplier: {
                value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
            },
            aspectMult: {
                value: 1.0
            }
        }

        this.material = new THREE.ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: vertShader,
            fragmentShader: fragShader,
            blending: this.options.blendMode,
            depthTest: true,
            depthWrite: false,
            transparent: true,
            vertexColors: true
        })

        this.particles = []
        this.geometry = new THREE.BufferGeometry()
        this.points = new THREE.Points(this.geometry, this.material)

        scene.add(this.points)

        for (let i = 0; i < this.options.sRate; i++) {
            this.spawn()
        }

        this.bufferPositions = new THREE.Float32BufferAttribute(new Float32Array(this.options.sRate * 3), 3)
        this.bufferSizes = new THREE.Float32BufferAttribute(new Float32Array(this.options.sRate), 1)
        this.bufferColors = new THREE.Float32BufferAttribute(new Float32Array(this.options.sRate * 4), 4)
        this.bufferAngles = new THREE.Float32BufferAttribute(new Float32Array(this.options.sRate), 1)

        this.geometry.setAttribute('position', this.bufferPositions)
        this.geometry.setAttribute('size', this.bufferSizes)
        this.geometry.setAttribute('colour', this.bufferColors)
        this.geometry.setAttribute('angle', this.bufferAngles)

    }
    spawn() {
        const sizeNoise = Math.random() * this.options.vSize
        this.particles.push({
            // particle definition
            position: new THREE.Vector3(
                this.parent.position.x + ((Math.random() * 2 - 1) * this.options.sRange.x),
                this.parent.position.y + ((Math.random() * 2 - 1) * this.options.sRange.y),
                this.parent.position.z + ((Math.random() * 2 - 1) * this.options.sRange.z),
            ),
            size: this.options.sSize + (Math.random() * this.options.vSize),
            angle: Math.random() * this.options.vAngle,
            colour: this.options.sColour,
            alpha: this.options.sAlpha,
            //non attributes
            sVelocity: this.options.sVelocity.add(new THREE.Vector3(
                (Math.random() - 0.5) * 2 * this.options.vVelocity,
                (Math.random() - 0.5) * 2 * this.options.vVelocity,
                (Math.random() - 0.5) * 2 * this.options.vVelocity
            )),
            eVelocity: this.options.eVelocity.add(new THREE.Vector3(
                (Math.random() - 0.5) * 2 * this.options.vVelocity,
                (Math.random() - 0.5) * 2 * this.options.vVelocity,
                (Math.random() - 0.5) * 2 * this.options.vVelocity
            )),
            sSize: this.options.sSize + sizeNoise,
            // eSize: this.options.eSize + (Math.random() * this.options.vSize),
            eSize: this.options.eSize + sizeNoise,
            sAlpha: this.options.sAlpha,
            sColour: this.options.sColour,
            eColour: this.options.eColour,
            timeAlive: 0 - parseInt(Math.random() * this.options.lifeTime),
            alphaTime: 0 - parseInt(Math.random() * this.options.lifeTime),
            lifeTime: this.options.lifeTime,
            holdTime: this.options.lifeTime - this.options.fadeInTime - this.options.fadeOutTime,
            fadeInTime: this.options.fadeInTime,
            fadeOutTime: this.options.fadeOutTime,
        })
    }
    update() {

        // this.sortParticles()

        this.particles.forEach((p, i) => {

            p.timeAlive += 1
            // p.alphaTime += this.options.alphaScale

            const loopTime = p.timeAlive % p.lifeTime
            // const loopAlphaTime = p.alphaTime % p.lifeTime
            const timeline = loopTime / p.lifeTime

            if (loopTime == 0) this.resetLoop(i)

            if (loopTime < p.fadeInTime && this.options.fadeIn) { //in
                p.alpha = map(loopTime, 0, p.fadeInTime, 0, p.sAlpha, true)
            } else if (this.options.fadeOut) { //out
                p.alpha = map(loopTime, p.fadeInTime + p.holdTime, p.lifeTime, p.sAlpha, 0, true)
            }

            if (this.options.modSize) {
                p.size = lerp(p.sSize, p.eSize, timeline)
            }
            
            p.colour.copy(p.sColour)
            p.colour.lerp(p.eColour, timeline)
            p.position.add(new THREE.Vector3().lerpVectors(p.sVelocity, p.eVelocity, timeline).multiplyScalar(this.options.motionScale))

            this.bufferPositions.array.set([p.position.x, p.position.y, p.position.z], i * 3)
            this.bufferSizes.array[i] = p.size
            this.bufferColors.array.set([p.colour.x, p.colour.y, p.colour.z, p.alpha], i * 4)
            this.bufferAngles.array[i] = p.angle

        })

        this.geometry.attributes.position.needsUpdate = true
        this.geometry.attributes.size.needsUpdate = true
        this.geometry.attributes.colour.needsUpdate = true
        this.geometry.attributes.angle.needsUpdate = true

    }
    resetLoop(i) {
        const sizeNoise = Math.random() * this.options.vSize
        // need to put here for updates via setOption
        this.particles[i].position.x = this.parent.position.x + ((Math.random() * 2 - 1) * this.options.sRange.x)
        this.particles[i].position.y = this.parent.position.y + ((Math.random() * 2 - 1) * this.options.sRange.y)
        this.particles[i].position.z = this.parent.position.z + ((Math.random() * 2 - 1) * this.options.sRange.z)
        this.particles[i].sSize = this.options.sSize + sizeNoise
        this.particles[i].eSize = this.options.eSize + sizeNoise
        this.particles[i].sAlpha = this.options.sAlpha
    }
    sortParticles() {
        //distance sort
        this.particles.sort((a, b) => {
            const d1 = this.camera.position.distanceTo(a.position)
            const d2 = this.camera.position.distanceTo(b.position)

            if (d1 > d2) {
                return -1
            }

            if (d2 > d1) {
                return -1
            }

            return 0
        })
    }
    setOption(option, value) {
        if (Array.isArray(option) && Array.isArray(value)) {
            option.forEach((el, i) => {
                this.options[el] = value[i]
            })
        } else {
            this.options[option] = value
        }
    }
    // resize() {
    //     //particles size wont resize by themselves after init
    //     this.uniforms.aspectMult.value = this.initAspect / this.camera.aspect
    //     this.material.needsUpdate = true
    // }
    debug() {
        // console.log(this.particles)
        // console.log(this.bufferColors.array)
        // console.log(this.particles[0].fadeInTime, this.particles[0].holdTime, this.particles[0].fadeOutTime)
        console.log(this.particles[0].sAlpha)
    }
}