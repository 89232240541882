import './styles/style.scss'
import * as THREE from 'three'
import { noise, map, constrain, lerp } from './modules/util.js'
import { MeshLine, MeshLineMaterial } from './modules/meshline.js'
import { CatmullRomCurve3 } from './modules/fastCatmull'
import { particle } from './modules/particle.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

//data
import { screenPhaseList } from './data/list'

//SETUP
const canvas = document.getElementById('three-canvas')
let width = window.innerWidth
let height = window.innerHeight
window.addEventListener('resize', resize)
history.scrollRestoration = "manual"

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: false,
})
renderer.debug.checkShaderErrors = false
renderer.setClearColor(0x000000, 0)
renderer.physicallyCorrectLights = false
renderer.setSize(width, height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.2))
renderer.shadowMap.enabled = false
const composer = new EffectComposer(renderer)

const group1 = new THREE.Group() //main group
const scene1 = new THREE.Scene()



//LOADING
const loadManager = new THREE.LoadingManager()
const loadStates = {
    contentShown: false,
    webgl: false,
    fonts: false
}
loadManager.onStart = function (url, itemsLoaded, itemsTotal) {
    // console.log(`LOAD: beginning load`)
    // console.log(`LOAD: loading ${url} ${itemsLoaded}/${itemsTotal}`)
}
loadManager.onProgress = function (url, itemsLoaded, itemsTotal) {
    // console.log(`LOAD: loading ${url} ${itemsLoaded}/${itemsTotal}`)
}
loadManager.onLoad = function () {
    // console.log('LOAD: assets loaded')
    loadStates.webgl = true
}
loadManager.onError = function (url) {
    // console.log(`LOAD: error at ${url}`)
}



//SCENE SPECIFIC VARS
let time = 0
let bannerLock = true
let lockLerp = 0
const mouse = new THREE.Vector3(0, 0.5, 0)
let pMouse = mouse.clone()
const mouseWorld = new THREE.Vector3(0, 0.5, 0)
const mouseWorldSmooth = mouseWorld.clone()
const mouseSpeed = new THREE.Vector2()


let sampleMouse = {
    x: 0,
    y: 0,
    xDelta: 0,
    yDelta: 0,
    xDelta2: 0,
    yDelta2: 0,
}
let pSampleMouse = { ...sampleMouse }
const sampleTime = 5


let isMoving = false
let lerpNotMoving = 1
const isMovingDebounce = 40
let isScrolling = false
const isScrollingDebounce = 40

let brushParam = {
    lineSegments: 150,
    lineSpeed: 0.2,
    lineThickness: 0.5,
    opacity: 0.5,
    noisePeriod: 0.04,
    noiseScale: 0.02,
    mInfluence: 0.26,
    x: 0,
    y: 0,
    xSinAmt: 0.05,
    ySinAmt: 0.05,
    xSinPeriod: 0.1,
    ySinPeriod: 0.1,
    resolutionScale: 6,
}
window.brushParam = brushParam
let particleParam = {
    motionScale: 1,
    alphaScale: 1,
    colorScale: 1,
}
let camParam = {
    camDistance: 3,
    camFov: 130,
}
let brushParamCallback = {
    x: (val) => {
        brushParam.x += (val - brushParam.x) * 0.05
    },
    y: (val) => {
        brushParam.y += (val - brushParam.y) * 0.05
    },
    lineThickness: (val) => {
        const trueVal = val
        if (time <= 300) {
            val = map(time, 60, 300, 0, trueVal, true)
        }
        brushParam.lineThickness += (val - brushParam.lineThickness) * 0.3
    },
    opacity: (val) => {
        brushParam.opacity += (val - brushParam.opacity) * 0.1
        brushLineMesh.material.opacity = brushParam.opacity
        brushLineMesh.material.needsUpdate = true
    },
    lineSpeed: (val) => {
        brushParam.lineSpeed = val + map(scrollYDelta, -5, 5, -40, 40, true)
    },
    mInfluence: (val) => {
        const trueVal = val
        if (time <= 300) {
            val = map(time, 100, 300, 0, trueVal, true)
        }
        brushParam.mInfluence = val
    }
}
const brushPathPoints = new Float32Array(brushParam.lineSegments * 3)
const brushPathPointsBuffer = new Float32Array(brushParam.lineSegments * 3)

let curveInit = []
for (let i = 0; i < brushParam.lineSegments; i++) {
    const n = i * 3
    curveInit.push(new THREE.Vector3(-1, -1, -1))
    brushPathPoints[n] = mouseWorldSmooth.x
    brushPathPoints[n + 1] = 0.5
    brushPathPoints[n + 2] = mouseWorldSmooth.z
}
brushPathPointsBuffer.set(brushPathPoints)
const curve = new CatmullRomCurve3(curveInit, false, 'centripetal', 0.5, brushParam.lineSegments, brushParam.resolutionScale)

const sceneParticles = []

let screenPhase = 0

function rgbToVec(r, g, b) {
    return new THREE.Vector3(r / 255, g / 255, b / 255)
}

//bg
const colour1 = new THREE.Color(0xe4d6b5)
const colour1Vec = rgbToVec(228, 214, 181)
//fog
const colour2 = new THREE.Color(0xc78c52)
const colour2Vec = rgbToVec(199, 140, 82)
//brush
const colour3 = new THREE.Color(0x000000)
const colour3Vec = rgbToVec(0, 0, 0)



//DOM
let scrollY = 0
let scrollYDelta = 0
let scrollEase = 0.005



//CAM + LIGHTS

// scene1.background = new THREE.TextureLoader(loadManager).load('./images/parchment.jpg')
// scene1.fog = new THREE.FogExp2(new THREE.Color(colour1), 0.01)

const camera1 = new THREE.PerspectiveCamera(camParam.camFov, width / height, 0.001, 150)
camera1.position.set(0, 0.5, 2)
camera1.updateProjectionMatrix()
scene1.add(camera1)

//OBJECTS
const brushLine = new MeshLine()
brushLine.setPoints(brushPathPoints);
const brushLineMesh = new THREE.Mesh(
    brushLine,
    new MeshLineMaterial({
        useMap: 0,
        alphaMap: new THREE.TextureLoader(loadManager).load('./images/brush_alpha.png'),
        useAlphaMap: 1,
        alphaTest: 0.2,
        transparent: true,
        depthTest: true,
        depthWrite: false, //brush will currently render under, switch if needed but has weird opacity
        blending: THREE.NormalBlending,
        sizeAttenuation: 1,
        resolution: new THREE.Vector2(width, height),
        color: colour3,
        lineWidth: brushParam.lineThickness
    })
)
group1.add(brushLineMesh)

const brushPEmit = new THREE.Object3D
group1.add(brushPEmit)
const brushP1 = new particle(new THREE.TextureLoader(loadManager).load('./images/blot3_alpha.png'), brushPEmit, group1, camera1, {
    sColour: colour3Vec, eColour: colour3Vec, sAlpha: 0.0,
    sRange: new THREE.Vector3(0.2, 0.2, 0.1), sRate: 10,
    sSize: 0.0, eSize: 0.05, vSize: 0, modSize: true,
    sVelocity: new THREE.Vector3(0, 0, 0.015), eVelocity: new THREE.Vector3(0, 0, 0.002),
    lifeTime: 35, fadeInTime: 3, fadeOutTime: 15
})
sceneParticles.push(brushP1)
window.brushP1 = brushP1

//dust
const pEmit2 = new THREE.Object3D
pEmit2.position.set(0, 3, -9)
group1.add(pEmit2)
const p2 = new particle(new THREE.TextureLoader(loadManager).load('./images/point2_alpha.png'), pEmit2, group1, camera1, {
    sColour: colour2Vec, eColour: colour2Vec, sAlpha: 0.8,
    sRange: new THREE.Vector3(11, 8, 0), sRate: 20,
    sSize: 0.004, eSize: 0, vSize: 0.04,
    sVelocity: new THREE.Vector3(0, 0, 0.5), eVelocity: new THREE.Vector3(0, -0.01, 0.02),
    lifeTime: 180, fadeInTime: 15, fadeOutTime: 40
})
sceneParticles.push(p2)

//fog
const pEmit3 = new THREE.Object3D
pEmit3.position.set(0, 0, -24)
group1.add(pEmit3)
const p3 = new particle(new THREE.TextureLoader(loadManager).load('./images/blot1_alpha.png'), pEmit3, group1, camera1, {
    sColour: colour2Vec, eColour: colour2Vec, sAlpha: 0.15,
    sRange: new THREE.Vector3(80, 35, 0), sRate: 20,
    sSize: 3, eSize: 3, vSize: 20, modSize: false,
    sVelocity: new THREE.Vector3(0, 0, 0.4), eVelocity: new THREE.Vector3(0, 0, 0.02),
    lifeTime: 80, fadeInTime: 30, fadeOutTime: 50,
    blendMode: THREE.NormalBlending
})
sceneParticles.push(p3)

scene1.add(group1)



//POST PROCESSING
const renderPass = new RenderPass(scene1, camera1);
composer.addPass(renderPass)



let moveIgnoreOver = false
const moveIgnore = [
    'main-nav__actions__cta',
    'main-banner__cta',
    'utility-nav__list__item',
    'utility-nav__list__item__text',
    'main-content__actions__cta',
    'main-banner__logo',
    'main-banner__logo--style-1',
    'main-banner__logo--style-2',
    'main-banner__content',
]
//METHODS
function mouseMove(event) {

    if (event.target.classList && moveIgnore.some((el) => event.target.classList.contains(el)) && event.type == 'mousemove') {
        moveIgnoreOver = true
    } else {
        moveIgnoreOver = false
    }

    if (bannerLock) {
        event.preventDefault()
    }

    if (screenPhaseList[screenPhase].lock) return

    pMouse = mouse.clone()

    if (event.clientX == undefined || event.clientY == undefined) {
        event.clientX = event.touches[0].clientX
        event.clientY = event.touches[0].clientY
    }
    // normalized mouse coordinates
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1

    updateMoveState()
}


let pTouchY = null
function setScroll(event) {
    if (event.clientY === undefined) {
        event.clientY = event.touches[0].clientY
    }
    pTouchY = event.clientY
}

function scroll(event) {
    let delta = 0
    switch (event.type) {
        case 'wheel':
            delta = event.deltaY
            break
        case 'touchmove':
            event.preventDefault()
            if (event.clientY === undefined) {
                event.clientY = event.touches[0].clientY
            }
            delta = bannerLock ? 0 : pTouchY - event.clientY
            pTouchY = event.clientY
            break
        case 'keydown':
            if (event.keyCode == 38) {
                delta = -40
            }
            if (event.keyCode == 40) {
                delta = 40
            }
            break
        default:
            break
    }

    delta = constrain(delta, -40, 40)

    scrollTarget += delta
    if (scrollTarget < 0) {
        scrollTarget = 0
    }
    if (scrollTarget > totalHeight) {
        scrollTarget = totalHeight
    }
    scrollDistTarget = scrollTarget - items[screenPhase].acmScrollHeight
    if (scrollDistTarget < 0) {
        scrollDistTarget = 0
    }
    if (scrollDistTarget > distanceHeight) {
        scrollDistTarget = distanceHeight
    }

    updateScrollState()
    updateMoveState()
    scrollCheck()
    moveIgnoreOver = false
}

let mouseMoveTimeout
function updateMoveState() {
    isMoving = true
    clearTimeout(mouseMoveTimeout)
    mouseMoveTimeout = setTimeout(() => {
        isMoving = false
        pMouse = mouse.clone()
    }, isMovingDebounce)
}

let scrollingTimeout
function updateScrollState() {
    isScrolling = true
    clearTimeout(scrollingTimeout)
    scrollingTimeout = setTimeout(() => {
        isScrolling = false
    }, isScrollingDebounce)
}

function mouseLock() {
    pMouse = mouse.clone()

    mouse.x = brushParam.x + (brushParam.xSinAmt * Math.cos(time * brushParam.xSinPeriod))
    mouse.y = brushParam.y + (brushParam.ySinAmt * Math.sin(time * brushParam.ySinPeriod)) * (width / height)
}

function calcMouseWorlds() {

    const unproject = mouse.clone()
    unproject.unproject(camera1)
    unproject.sub(camera1.position).normalize();
    mouseWorld.copy(camera1.position).add(unproject.multiplyScalar(camParam.camDistance))

    mouseSpeed.x = mouse.x - pMouse.x
    mouseSpeed.y = mouse.y - pMouse.y

    mouseWorldSmooth.x = lerp(mouseWorldSmooth.x, mouseWorld.x, brushParam.mInfluence)
    mouseWorldSmooth.y = lerp(mouseWorldSmooth.y, mouseWorld.y, brushParam.mInfluence)
    mouseWorldSmooth.z = lerp(mouseWorldSmooth.z, mouseWorld.z, brushParam.mInfluence)
}

function updateBrushVars() {
    for (const prop in brushParam) {
        if (screenPhaseList[screenPhase][prop] === undefined) continue
        if (brushParamCallback[prop]) {
            brushParamCallback[prop](screenPhaseList[screenPhase][prop])
        } else {
            brushParam[prop] = screenPhaseList[screenPhase][prop]
        }
    }
}

function mouseSample() {
    sampleMouse.x = mouse.x
    sampleMouse.y = mouse.y
    sampleMouse.xDelta = pSampleMouse.x - sampleMouse.x
    sampleMouse.yDelta = pSampleMouse.y - sampleMouse.y
    sampleMouse.xDelta2 = sampleMouse.xDelta - pSampleMouse.xDelta
    sampleMouse.yDelta2 = sampleMouse.yDelta - pSampleMouse.yDelta
    pSampleMouse = { ...sampleMouse }
}

function updateBrush() {
    updateBrushVars()
    if (time < 60) { //temp
        return
    }

    if (!isMoving && !screenPhaseList[screenPhase].lock && lerpNotMoving < 1) {
        lerpNotMoving += 0.004 * map(lerpNotMoving, 0.75, 1, 1, 0)
    } else if (lerpNotMoving > 0) {
        lerpNotMoving -= 0.02 * map(lerpNotMoving, 0, 0.25, 0, 1)
    }

    const period = brushParam.lineSegments
    const segLength = brushParam.lineSpeed / brushParam.lineSegments
    // const accelSq = Math.pow(sampleMouse.xDelta2, 2) + Math.pow(sampleMouse.yDelta2, 2)
    const velSq = Math.pow(sampleMouse.xDelta, 2) + Math.pow(sampleMouse.yDelta, 2)

    const loopLerp = map(lerpNotMoving, 0.2, 0.8, 0, 1, true)

    // const shiftCon = (isMoving || time%Math.round(Math.min(1-(lerpNotMoving * 2)), 1) == 0 || lerpNotMoving >= 0.8 || screenPhaseList[screenPhase].lock)
    // const shiftCon = (isMoving || lerpNotMoving >= 0.5 || screenPhaseList[screenPhase].lock)
    // const shiftCon = true

    curve.points[0].set(brushPathPoints[0], brushPathPoints[1], brushPathPoints[2])

    // easing to point
    brushPathPoints[0] = brushPathPointsBuffer[0] = lerp(brushPathPoints[0], mouseWorldSmooth.x, 0.85) +
        ((noise(time * brushParam.noisePeriod) - 0.5) * brushParam.noiseScale * (1 - loopLerp)) + Math.sin(time / 3) * 0.05 * loopLerp
    brushPathPoints[1] = brushPathPointsBuffer[1] = lerp(brushPathPoints[1], mouseWorldSmooth.y, 0.85) +
        ((noise(time * brushParam.noisePeriod + 100) - 0.5) * brushParam.noiseScale * (1 - loopLerp)) + Math.cos(time / 3) * 0.05 * loopLerp
    brushPathPoints[2] = brushPathPointsBuffer[2] = lerp(brushPathPoints[2], mouseWorldSmooth.z, 0.85)


    // shift down
    for (let i = 1; i < period; i++) {
        const n = i * 3

        brushPathPoints[n] = brushPathPointsBuffer[n - 3]
        brushPathPoints[n + 1] = brushPathPointsBuffer[n - 2]
        brushPathPoints[n + 2] = brushPathPointsBuffer[n - 1] + segLength

        curve.points[i].set(brushPathPoints[n], brushPathPoints[n + 1], brushPathPoints[n + 2]) //optimise this by writing directly to points
    }

    brushLine.setPoints(curve.getPoints(),
        p => brushParam.lineThickness * (0.3 + (0.7 * Math.sin(p / 2))) * map(lerpNotMoving, 0.85, 0.98, 1, 0, true) * map(p, 0, 0.03, 0, 1, true)

    )

    brushPathPointsBuffer.set(brushPathPoints)

    brushPEmit.position.set(mouseWorldSmooth.x, mouseWorldSmooth.y, mouseWorldSmooth.z + 0.2)

    if (!screenPhaseList[screenPhase].lock) {
        // brushP1.setOption(
        //     ['sAlpha', 'vSize'],
        //     [map(accelSq, 0.5, 0.7, 0, 1, true) * map(time, 0, 240, 0, 1, true), map(accelSq, 0.8, 2, 0.4, 0.6, true)]
        // )
        brushP1.setOption(
            ['sAlpha', 'vSize'],
            [map(velSq, 0.3, 0.4, 0, 1, true) * map(time, 0, 240, 0, 1, true), map(velSq, 0.3, 0.8, 0.3, 0.6, true)]
        )
    } else {
        brushP1.setOption(
            ['sAlpha', 'vSize'],
            [0, 0]
        )
    }
}

function updateParticles() {
    particleParam.motionScale = map(scrollYDelta, -6, 5, -1, 1, true)
    for (const p in sceneParticles) {
        sceneParticles[p].setOption('motionScale', particleParam.motionScale)
        sceneParticles[p].update()
    }
}


function updateRender() {
    renderer.render(scene1, camera1)
    // composer.render()
}




//DOM
const mainEl = document.getElementById('main')
const footerEl = document.getElementById('main-footer')
const mainNav = document.getElementById('main-nav')
const utilNav = document.getElementById('utility-nav')
const mainBg = document.getElementById('main-bg-img')
const perspectiveCont = document.getElementById('perspective-cont')
const perspectiveItems = Array.from(document.querySelectorAll('.perspective'))

let transMarginLong = 300
let transMarginStandard = 250
let transMarginShort = 150
let transMarginShorter = 50 // pretty much just overlaping

let focusMargin = 0 //minimum scroll space of a screen
let totalHeight = 1
let distanceHeight = 0 //totalHeight without sections scrolls
let scrollDistY = 0

let cullScroll = 0 //if scroll is less than this dont scroll

let items = []
let initRefPos = new THREE.Vector3(0, 0, 0) // reference point for scales

let scrollDeltaMult = 25
let scrollTarget = 0
let scrollDistTarget = 0

let clipParams = {
    nearA: 40,
    nearB: 20,
    farA: -60,
    farB: -140,
}

const inRange = (el) => { return (scrollY >= el.sScroll && scrollY <= el.eMargin) }
const inScroll = (el) => { return (scrollY >= el.sScroll && scrollY <= el.eScroll) }
const inOverlap = (el, prevEl) => { return (scrollY > prevEl.eScroll + (prevEl.margin / 2) && scrollY <= el.eScroll) } //greater than last el plus half margin and less than current

function calcScrollTimeline() {

    transMarginShorter = window.innerWidth < 576 ? 0 : 50
    cullScroll = Math.min(window.innerHeight / 12, 60)

    const prevScrollY = scrollY

    totalHeight = 0
    distanceHeight = 0
    items = []
    perspectiveItems.forEach((el, i) => {
        el.style.zIndex = perspectiveItems.length - i
        const scrollEl = el.getElementsByClassName('perspective-scroll')
        const long = el.classList.contains('perspective--long')
        const short = el.classList.contains('perspective--short')
        const shorter = el.classList.contains('perspective--shorter')
        let marg = 0
        if (shorter) {
            marg = transMarginShorter
        } else if (short) {
            marg = transMarginShort
        } else if (long) {
            marg = transMarginLong
        } else {
            marg = transMarginStandard
        }
        let paddingY = 0
        let contY = 0
        if (scrollEl[0]) {
            scrollEl[0].removeAttribute('style')
            const style = getComputedStyle(scrollEl[0])
            paddingY = parseInt(style.paddingTop) + parseInt(style.paddingBottom)
            contY = scrollEl[0].clientHeight - (parseInt(style.paddingTop) + parseInt(style.paddingBottom))
        }
        let scrlHeight = scrollEl[0] ? (scrollEl[0].scrollHeight - paddingY - contY ? scrollEl[0].scrollHeight - paddingY - contY : focusMargin) : focusMargin
        scrlHeight = scrlHeight > cullScroll ? scrlHeight : 0
        const tMargin = (i < perspectiveItems.length - 1) ? marg : 0

        const sScroll = totalHeight
        totalHeight += (scrlHeight + tMargin)

        items.push({
            el: el,
            scrollEl: scrollEl,
            sScroll: sScroll,
            eScroll: totalHeight - tMargin,
            eMargin: totalHeight,
            margin: tMargin,
            spacePos: 0,
            scrollHeight: scrlHeight,
            acmScrollHeight: 0,
            initLgt: 0,
            curLgt: 0,
            trns: 0,
            opac: 0,
            blur: 0,
            inView: true,
            reRender: false,
        })

        let prevScrollSum = 0
        for (let j = 0; j <= i; j++) {
            items[j].acmScrollHeight = prevScrollSum
            if (j == i) break
            prevScrollSum += items[j].scrollHeight
        }
        items[i].spacePos = sScroll - prevScrollSum
        distanceHeight = sScroll - prevScrollSum
    })

    if (prevScrollY >= totalHeight) {
        scrollToPos(totalHeight - 1)
        scrollY = totalHeight - 1
    }
    // logItems()
}

function logItems() {
    console.log(items)
}
// window.logItems = logItems

//controls scroll animations
let curScroll = 0
function scrollTimeline() {
    const prevPhase = screenPhase

    scrollYDelta = constrain(scrollDeltaMult * Math.ceil(scrollTarget - scrollY) * scrollEase, -20, 20)
    // scrollYDelta = scrollDeltaMult * Math.ceil(scrollTarget - scrollY) * scrollEase
    scrollY += scrollYDelta

    // overscroll
    scrollTarget = constrain(scrollTarget, 0, totalHeight)
    scrollDistTarget = constrain(scrollDistTarget, 0, distanceHeight)
    scrollY = constrain(scrollY, 0, totalHeight)
    scrollDistY = constrain(scrollDistY, 0, distanceHeight)

    if (inScroll(items[screenPhase])) {
        curScroll = items[screenPhase].acmScrollHeight + (scrollY - items[screenPhase].sScroll)
    } else {
        if (scrollY >= items[screenPhase].eScroll) {
            curScroll = items[screenPhase].acmScrollHeight + items[screenPhase].scrollHeight
        }
        if (scrollY <= items[screenPhase].sScroll) {
            curScroll = items[screenPhase].acmScrollHeight
        }
    }

    scrollDistY = scrollY - curScroll

    let nearestDist = totalHeight
    for (let i = screenPhase - 1; i <= screenPhase + 1; i++) {
        if (i < 0 || i > items.length - 1) {
            continue
        }
        const diff = scrollDistY - items[i].spacePos
        if (Math.abs(diff) < nearestDist) {
            nearestDist = diff
        }
    }
    if (!isScrolling && Math.abs(nearestDist) < 120 && nearestDist < 0) {
        scrollTarget -= nearestDist * 0.015
        scrollDistTarget -= nearestDist * 0.015
    }
    // document.getElementById('debug').textContent = `${nearestDist}`

    if (!bannerLock) {
        lockLerp += (1 - lockLerp) * 0.05
    } else {
        lockLerp /= 1.4
    }

    if (loadStates.contentShown) {
        if (moveIgnoreOver) {
            canvas.style.opacity = 0.7
            canvas.style.transitionDelay = '0ms'
        } else {
            canvas.style.opacity = 1
        }
    }

    //items loop
    for (let i = 0; i < items.length; i++) {
        const item = items[i]

        //transform
        item.trns = (scrollDistY - item.spacePos) * 0.5

        //opacity and blur
        if (item.trns < clipParams.farA) {
            item.opac = map(item.trns, clipParams.farB, clipParams.farA, -0.1, 1 * lockLerp, true)
            item.blur = map(item.trns, clipParams.farB, clipParams.farA, 1.5, 0, true)
        } else if (item.trns > clipParams.nearB) {
            item.opac = map(item.trns, clipParams.nearB, clipParams.nearA, 1, -0.1, true)
            item.blur = map(item.trns, clipParams.nearB, clipParams.nearA, 0, 1.5, true)
        } else {
            item.opac = 1
            item.blur = 0
        }

        //render
        if (item.reRender) { //force rerender
            // item.el.style.willChange = 'auto'
            item.el.style.webkitTransform = `translate3d(0,0,0px)`
            item.el.style.transform = `translate3d(0,0,0px)`
            item.el.style.opacity = 0
            // item.el.style.visibility = 'hidden'
            item.el.style.filter = `none`
            item.reRender = false
        } else { //normal render
            // item.el.style.willChange = 'transform'
            item.el.style.webkitTransform = `translate3d(0,0,${item.trns}px)`
            item.el.style.transform = `translate3d(0,0,${item.trns}px)`
            item.el.style.opacity = item.opac
            // item.el.style.visibility = 'visible'
            item.el.style.filter = `blur(${item.blur}px)`
        }

        //scroll
        if (item.scrollEl[0]) {
            if (scrollY < item.sScroll) {
                item.scrollEl[0].style.webkitTransform = 'translateY(0px)'
                item.scrollEl[0].style.transform = 'translateY(0px)'
            } else if (scrollY > item.eScroll) {
                item.scrollEl[0].style.webkitTransform = `translateY(${-item.scrollHeight}px)`
                item.scrollEl[0].style.transform = `translateY(${-item.scrollHeight}px)`
            } else {
                item.scrollEl[0].style.webkitTransform = `translateY(${map(scrollY, item.sScroll, item.eScroll, 0, -item.scrollHeight, true)}px)`
                item.scrollEl[0].style.transform = `translateY(${map(scrollY, item.sScroll, item.eScroll, 0, -item.scrollHeight, true)}px)`
            }
        }

        //phase change
        if (items[i - 1] && inOverlap(item, items[i - 1])) {
            screenPhase = i
        } else if (inRange(item)) {
            screenPhase = i
        }

        //switch rerender / force rendering at a reasonable scale
        if (item.trns >= clipParams.nearA || item.trns <= clipParams.farB) {
            item.inView = false
            item.reRender = true
            item.el.style.willChange = 'auto'
        } else {
            item.inView = true
            item.el.style.willChange = 'transform'
        }
    }

    if (prevPhase != screenPhase) {
        updateClass(screenPhase)
    }
}

function updateClass(index) {
    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        if (i == index) {
            item.el.classList.add('perspective--current')
        } else {
            item.el.classList.remove('perspective--current')
        }
    }
}
updateClass(0)

function scrollCheck() {
    if (scrollTarget <= 10 && window.innerHeight > 300) {
        bannerLock = true
    } else {
        bannerLock = false
    }

    if (scrollTarget >= totalHeight - 100) {
        footerEl.classList.add('main-footer--show')
        footerEl.classList.remove('main-footer--hide')
        utilNav.classList.add('utility-nav--hide')
    } else {
        footerEl.classList.remove('main-footer--show')
        footerEl.classList.add('main-footer--hide')
        utilNav.classList.remove('utility-nav--hide')
    }
}

function scrollToTimeline(e, index) {
    e.preventDefault()

    if (index == screenPhase) {
        return
    }

    if (index == 0 && window.innerHeight > 300) {
        bannerLock = true
    } else {
        bannerLock = false
    }
    scrollTarget = items[index].sScroll
    scrollCheck()
}
window.scrollToTimeline = scrollToTimeline

function scrollToPos(pos) {
    scrollTarget = pos
    scrollCheck()
}
window.scrollToPos = scrollToPos


function showDropdown(event) {
    event.target.closest('.main-content__text--dropdown').classList.toggle('main-content__text--dropdown--show')
    setTimeout(() => {
        calcScrollTimeline()
        scrollCheck()
    }, 50)
}
window.showDropdown = showDropdown

function showDropdownSection(event, id) {
    event.target.closest('.main-content__text--dropdown-cont-head').classList.toggle('main-content__text--dropdown-cont-head--show')
    document.getElementById(id).classList.toggle('main-content__text--dropdown-cont--show')
    setTimeout(() => {
        calcScrollTimeline()
        scrollCheck()
    }, 50)
}
window.showDropdownSection = showDropdownSection



//EVENTS
window.addEventListener('mousemove', mouseMove)
window.addEventListener('touchmove', mouseMove, { passive: false })
window.addEventListener('wheel', scroll)
window.addEventListener('keydown', scroll)
window.addEventListener('touchmove', scroll, { passive: false })
window.addEventListener('touchstart', setScroll)
// window.addEventListener('scroll', scroll)



//LOOP/LIFECYCLE
function resize() {
    width = window.innerWidth
    height = window.innerHeight

    camera1.aspect = width / height
    camera1.updateProjectionMatrix()

    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1.2))
    renderer.setSize(width, height)

    if (width > height) {
        mainBg.style.width = '100%'
        mainBg.style.height = '100%'
    } else {
        mainBg.style.width = 'auto'
        mainBg.style.height = '100%'
    }

    calcScrollTimeline()
}
resize()
document.fonts.ready.then(() => {
    loadStates.fonts = true
    resize()
})

function loadComplete() {
    if (loadStates.fonts && loadStates.webgl) {
        loadStates.contentShown = true
        canvas.classList.add('main-webgl--show')
        mainBg.parentElement.classList.add('main-bg--show')
        document.getElementById('main-banner').classList.add('main-banner--show')
        document.getElementById('main-loading').classList.add('main-loading--hide')
        utilNav.classList.remove('utility-nav--hide')
    }
}

function loop() {

    time += 1
    // renderer.info.reset()

    if (time % sampleTime === 0) {
        mouseSample()
    }

    if (!loadStates.contentShown) {
        loadComplete()
    }

    updateParticles()
    if (screenPhaseList[screenPhase].lock) {
        mouseLock()
    }
    calcMouseWorlds()
    updateBrush()
    scrollTimeline()
    updateRender()

    mouseSpeed.x = 0
    mouseSpeed.y = 0

    window.requestAnimationFrame(loop)
}
loop()