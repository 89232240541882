//scenes
export const screenPhaseList = [
    {
        //banner
        lock: false, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.00,
        ySinAmt: 0.00,
        xSinPeriod: 0.01,
        ySinPeriod: 0.01,
        mInfluence: 0.9,
        lineThickness: 1.0,
        opacity: 1,
        lineSpeed: 0.01,
        noisePeriod: 0.06,
        noiseScale: 0.1,
    },
    {
        //immersive
        lock: true, // mouse lock
        x: 0.5, // lock to x (-1, 1)
        y: -0.7, // lock to y (-1, 1)
        xSinAmt: 0.1,
        ySinAmt: 0.05,
        xSinPeriod: 0.01,
        ySinPeriod: 0.04,
        mInfluence: 0.15,
        lineThickness: 1.1,
        opacity: 1,
        lineSpeed: 3.5,
        noisePeriod: 0.05,
        noiseScale: 0.01,
    },
    {
        //experience
        lock: true, // mouse lock
        x: -0.5, // lock to x (-1, 1)
        y: -0.7, // lock to y (-1, 1)
        xSinAmt: 0.1,
        ySinAmt: 0.05,
        xSinPeriod: 0.01,
        ySinPeriod: 0.04,
        mInfluence: 0.15,
        lineThickness: 1.1,
        opacity: 1,
        lineSpeed: 3.5,
        noisePeriod: 0.07,
        noiseScale: 0.05,
    },
    {
        //poem1
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: -0.7, // lock to y (-1, 1)
        xSinAmt: 0.8,
        ySinAmt: 0.05,
        xSinPeriod: 0.03,
        ySinPeriod: 0.05,
        mInfluence: 0.15,
        lineThickness: 1.1,
        opacity: 1,
        lineSpeed: 3.5,
        noisePeriod: 0.05,
        noiseScale: 0.01,
    },
    {
        //exquisite
        lock: true, // mouse lock
        x: -0.5, // lock to x (-1, 1)
        y: -0.7, // lock to y (-1, 1)
        xSinAmt: 0.1,
        ySinAmt: 0.05,
        xSinPeriod: 0.01,
        ySinPeriod: 0.04,
        mInfluence: 0.15,
        lineThickness: 1.1,
        opacity: 1,
        lineSpeed: 3.5,
        noisePeriod: 0.05,
        noiseScale: 0.01,
    },
    {
        //discover
        lock: true, // mouse lock
        x: 0.5, // lock to x (-1, 1)
        y: -0.7, // lock to y (-1, 1)
        xSinAmt: 0.1,
        ySinAmt: 0.05,
        xSinPeriod: 0.01,
        ySinPeriod: 0.04,
        mInfluence: 0.15,
        lineThickness: 1.1,
        opacity: 1,
        lineSpeed: 3.5,
        noisePeriod: 0.05,
        noiseScale: 0.01,
    },
    {
        //poem2
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: -0.7, // lock to y (-1, 1)
        xSinAmt: 0.8,
        ySinAmt: 0.05,
        xSinPeriod: 0.03,
        ySinPeriod: 0.05,
        mInfluence: 0.15,
        lineThickness: 1.1,
        opacity: 1,
        lineSpeed: 3.5,
        noisePeriod: 0.05,
        noiseScale: 0.01,
    },
    {
        //info
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 2,
        ySinAmt: 2,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0,
        opacity: 1,
        lineSpeed: -10,
        noisePeriod: 0.05,
        noiseScale: 0.01,
    },

    
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
    {
        lock: true, // mouse lock
        x: 0, // lock to x (-1, 1)
        y: 0, // lock to y (-1, 1)
        xSinAmt: 0.05,
        ySinAmt: 0.05,
        xSinPeriod: 0.1,
        ySinPeriod: 0.1,
        mInfluence: 0.15,
        lineThickness: 0.8,
        opacity: 1,
        // lineSpeed: 0.2,
        noisePeriod: 0.04,
        noiseScale: 0.02,
    },
]